import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import LoginForm from 'views/Login/LoginForm';
import logo from 'assets/img/ZinkinData_100_25.png';
import logoVic from 'assets/img/vic_new.png';
import logoTorello from 'assets/img/logo_AjT_ALT_marro.png';
import i18next from 'i18next';
import { Card, Flex, Space } from 'antd';

const LoginPage = (props) => {
  const [centerLogo, setCenterLogo] = useState(logo);

  const setManifest = (centerName) => {
    const domain = document.location.origin;
    const currentUrl = window.location.href;
    const myDynamicManifest = {
      short_name: 'ZinkinData Portal ' + centerName,
      name: 'ZinkinData Portal ' + centerName,
      description: 'ZinkinData Portal ' + centerName,
      icons: [
        {
          src: domain + '/512.png',
          sizes: '512x512',
          type: 'image/png',
        },
      ],
      start_url: currentUrl,
      display: 'standalone',
      theme_color: '#000000',
      background_color: '#ffffff',
    };
    const stringManifest = JSON.stringify(myDynamicManifest);
    const blob = new Blob([stringManifest], { type: 'application/javascript' });
    const manifestURL = URL.createObjectURL(blob);
    document.getElementById('manifest').setAttribute('href', manifestURL);
  };

  useEffect(() => {
    // Determine the center based on the URL
    const pathVariables = props.history.location.pathname.split('/');
    const center = pathVariables[pathVariables.length - 1];
    if (center !== 'login') {
      localStorage.setItem('center', center.toLowerCase());
    } else {
      localStorage.setItem('center', 'nexotech');
    }

    // Set the logo and manifest based on the center
    try {
      switch (center.toLowerCase()) {
        case 'torello':
          setManifest('Torello');
          setCenterLogo(logoTorello);
          break;
        case 'vic':
          setManifest('Vic');
          setCenterLogo(logoVic);
          break;
        case 'rister':
          setManifest('Rister');
          setCenterLogo('https://rister.com/img/ristercom-logo-1568124729.jpg');
          break;
        default:
          setManifest('Nexotech');
          break;
      }
    } catch (error) {
      console.error(error);
    }

    // Set the language based on the TLD
    const hostname = window.location.hostname;
    const parts = hostname.split('.');
    const tld = parts.length > 1 ? parts[parts.length - 1] : '';

    switch (tld) {
      case 'cat':
        i18next.changeLanguage('ca');
        break;
      case 'es':
        i18next.changeLanguage('es');
        break;
      case 'eus':
        i18next.changeLanguage('eus');
        break;
      case 'gal':
        i18next.changeLanguage('ga');
        break;
      default:
        i18next.changeLanguage('es');
        break;
    }
  }, [props.history.location.pathname]);

  return (
    <Flex
      justify="center"
      align="center"
      direction="vertical"
      style={{ width: '100%', marginTop: '10%' }}
    >
      <Card style={{ maxWidth: '400px', margin: 'auto' }}>
        <div>
          <div style={{ textAlign: 'center' }}>
            <img
              style={{ maxWidth: '70%' }}
              alt="Logo ZinkiData"
              src={centerLogo}
            />
          </div>
          <div style={{ margin: '25px 0 -20px 0px' }}>
            <LoginForm
              onLogin={props.onLogin}
              onChangePassword={props.onChangePassword}
              {...props}
            />
          </div>
        </div>
      </Card>
    </Flex>
  );
};

export default withTranslation('common')(LoginPage);
