import LoginPage from 'views/Login/LoginPage.jsx';
import FirstLoginPage from 'views/Login/LoginPage.jsx';
import VerifiedEmail from 'views/VerifiedEmail/VerifiedEmail';

var pagesRoutes = [
  {
    path: '/pages/change-password',
    component: FirstLoginPage,
  },
  {
    path: '/pages/verify-email',
    component: VerifiedEmail,
  },
];

export default pagesRoutes;
