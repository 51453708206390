import React, { useEffect, useState } from 'react';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { withTranslation } from 'react-i18next';
import { Layout, Grid, notification, Button, Tooltip, Flex, Typography } from 'antd';
import { RiMenuFoldLine, RiMenuUnfoldLine } from 'react-icons/ri';

import dashboardRoutes from 'routes/dashboard.jsx';
import colors from '../util/colors';
import { LuLogOut } from 'react-icons/lu';

const { Header } = Layout;
const { useBreakpoint } = Grid;

const CustomHeader = (props) => {
  const { location, t } = props;
  const screens = useBreakpoint();
  const [sectionName, setSectionName] = useState(undefined);
  const [sectionIcon, setSectionIcon] = useState(undefined);

  useEffect(getSectionName, [props.location.pathname]);

  function getSectionName() {
    const route = dashboardRoutes.find((route) => {
      if (route.collapse) {
        return route.views.some((view) => view.path === location.pathname);
      }
      return route.path === location.pathname || (route.redirect && route.path === location.pathname);
    });

    if (!route) {
      setSectionIcon(undefined);
      setSectionName(t(undefined));
      return;
    }

    setSectionIcon(() => route.icon || null);
    setSectionName(t(route.name));
  }

  const onLogoutClick = () => {
    props.handleLogout();
    notification.success({
      message: 'ZinkinData Portal',
      description: t('header.exit-message'),
    });
  };

  return (
    <Header
      style={{
        padding: 0,
        background: 'white',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      {!screens.lg && (
        <Button
          type="text"
          icon={props.collapse ? <RiMenuUnfoldLine /> : <RiMenuFoldLine />}
          onClick={() => props.setCollapse(!props.collapse)}
          style={{
            fontSize: '20px',
            width: 64,
            height: 64,
          }}
        />
      )}

      <Flex
        align="center"
        justify="flex-end"
        style={{ float: 'right', width: '100%' }}
      >
        {sectionIcon &&
          React.createElement(sectionIcon, { style: { fontSize: '25px', color: '#777', marginLeft: 15 } })}

        <Typography.Title
          level={3}
          style={{
            margin: 10,
            color: '#777',
            fontFamily: 'Open Sans',
          }}
        >
          {sectionName && sectionName}
        </Typography.Title>

        <Tooltip
          title={t('header.exit')}
          color={colors.black.main}
          placement="left"
        >
          <Button
            type="text"
            icon={<LuLogOut />}
            onClick={() => onLogoutClick()}
            style={{
              fontSize: '20px',
              width: 64,
              height: 64,
              marginLeft: 'auto',
            }}
          ></Button>
        </Tooltip>
      </Flex>
    </Header>
  );
};

export default withTranslation('common')(CustomHeader);
