import React, { useState } from 'react';
import { Switch, Route } from 'react-router-dom';
import { Layout } from 'antd';

import dashboardRoutes from 'routes/dashboard.jsx';

import Sidebar from 'components/Sidebar.jsx';
import CustomHeader from 'components/CustomHeader.jsx';
import CustomFooter from 'components/CustomFooter.jsx';

const { Content } = Layout;

const Dashboard = (props) => {
  const [collapse, setCollapse] = useState(false);

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sidebar
        {...props}
        setCollapse={setCollapse}
        collapse={collapse}
      />

      <Layout style={{ marginLeft: collapse ? '0px' : '270px' }}>
        <CustomHeader
          {...props}
          setCollapse={setCollapse}
          collapse={collapse}
        />

        <Content
          style={{
            margin: '16px 16px',
            overflow: 'initial',
          }}
        >
          <Switch>
            {dashboardRoutes.map((view, key) => (
              <Route
                path={view.path}
                key={key}
                render={(routeProps) => (
                  <view.component
                    {...routeProps}
                    currentUser={props.currentUser}
                    currentCenter={props.currentCenter}
                    handleLogout={props.handleLogout}
                  />
                )}
              />
            ))}
          </Switch>
        </Content>

        <CustomFooter />
      </Layout>
    </Layout>
  );
};

export default Dashboard;
