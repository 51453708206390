import React, { useState } from 'react';
import { Input, Alert, Button as AntButton, Form, Modal, notification } from 'antd';
import * as UserRepository from '../repository/user/UserRepository';

const AddEmail = ({ currentUser, history }) => {
  const [emailModal, setEmailModal] = useState(false);

  const onFinish = (values) => {
    UserRepository.setEmailToUser(values.email).then(() => {
      notification['success']({
        message: 'ZinkinData Portal',
        description: 'Email afegit correctament.',
      });
      setEmailModal(false);
      currentUser.email = values.email;
      history.push('/license-plates');
    });
  };

  return (
    <>
      <Alert
        message="Email Obligatori"
        description="Afegeix el teu email per poder rebre la confirmació en inserir una nova matrícula a la llista blanca."
        type="error"
        style={{ width: '100%' }}
        action={
          <AntButton
            size="large"
            danger
            onClick={() => setEmailModal(true)}
          >
            Afegir email
          </AntButton>
        }
      />
      <Modal
        visible={emailModal}
        title="Afegir email"
        footer={null}
        onCancel={() => setEmailModal(false)}
      >
        <Form
          name="basic"
          autoComplete="off"
          size="large"
          onFinish={onFinish}
        >
          <Form.Item
            name="email"
            label={'Email'}
            rules={[
              {
                type: 'email',
                message: 'No és un email correcte.',
              },
              {
                required: true,
                message: 'És un camp obligatori',
              },
            ]}
            hasFeedback
          >
            <Input placeholder={'Email'} />
          </Form.Item>

          <Form.Item
            name="confirm"
            label={'Confirma email'}
            dependencies={['email']}
            hasFeedback
            rules={[
              {
                type: 'email',
                message: 'No és un email correcte.',
              },
              {
                required: true,
                message: 'És un camp obligatori',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('email') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error("L'email no coincideix"));
                },
              }),
            ]}
          >
            <Input placeholder={'Confirma email'} />
          </Form.Item>
          <Form.Item>
            <AntButton
              type="primary"
              htmlType="submit"
              width={'100%'}
            >
              Afegir email
            </AntButton>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default AddEmail;
