import React, { useState, useEffect, useCallback } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin, Table, Tooltip, Card, Flex, Typography } from 'antd';
import AddEmail from 'components/AddEmail.jsx';
import { MdAdd } from 'react-icons/md';
import { FaCircle } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import ButtonPrimary from 'components/Buttons/ButtonPrimary';
import moment from 'moment';
import colors from 'util/colors';
import { getZoneAuthorizationsPaginated } from 'repository/zoneAuthorization/ZoneAuthorizationRepository';
import { LuArrowLeft, LuArrowRight } from 'react-icons/lu';

const CitizenZoneAuthorizationsList = ({ currentUser, history }) => {
  const { t } = useTranslation('common');
  const [loading, setLoading] = useState(true);
  const [zoneAuthorizations, setZoneAuthorizations] = useState([]);
  const centername = localStorage.getItem('center');

  const [currentPage, setCurrentPage] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPreviousPage, setHasPreviousPage] = useState(false);

  const getZoneAuthorizationsFromUser = useCallback(async () => {
    try {
      const responseBody = await getZoneAuthorizationsPaginated({
        citizenId: currentUser.id,
        pageNumber: currentPage,
        pageSize: 10,
      });

      setTotalElements(responseBody.totalElements);
      setTotalPages(responseBody.totalPages);
      setHasNextPage(responseBody.totalPages > currentPage + 1);
      setHasPreviousPage(currentPage != 0);

      setZoneAuthorizations(responseBody.items);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [currentPage]);

  useEffect(() => {
    getZoneAuthorizationsFromUser();
  }, [getZoneAuthorizationsFromUser]);

  const columns = [
    {
      title: t('zone-authorizations.citizen.list.table.status'),
      dataIndex: 'validity',
      key: 'validity',
      align: 'center',
      render: (_, record) => {
        const validityTags = {
          APPROVED: (
            <Tooltip color={colors.blue.main} placement="top" title={t('zone-authorizations.validity.approved')}>
              <FaCircle style={{ color: '#28a745' }} />
            </Tooltip>
          ),
          PENDING: (
            <Tooltip placement="top" title={t('zone-authorizations.validity.pending')} color={colors.blue.main}>
              <FaCircle style={{ color: 'orange' }} />
            </Tooltip>
          ),
          DENIED: (
            <Tooltip placement="top" title={t('zone-authorizations.validity.denied')} color={colors.blue.main}>
              <FaCircle style={{ color: 'red' }} />
            </Tooltip>
          ),
        };
        return validityTags[record.validity] || <div></div>;
      },
    },
    {
      title: t('zone-authorizations.citizen.list.table.zone'),
      dataIndex: 'zoneName',
      key: 'zoneName',
    },
    {
      title: t('zone-authorizations.citizen.list.table.created-at'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: (a, b) => moment(a.createdAt) - moment(b.createdAt),
      defaultSortOrder: 'descend',
      render: (text, record) => (record.createdAt ? moment(record.createdAt).format('DD-MM-YYYY HH:mm:ss') : ''),
    },
    {
      title: t('zone-authorizations.citizen.list.table.reason'),
      dataIndex: 'reason',
    },
  ];

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  return (
    <div>
      {!currentUser.email && <AddEmail currentUser={currentUser} history={history} />}
      <Spin spinning={loading} indicator={antIcon}>
        <Card>
          <Flex gap="middle" vertical>
            {centername.toUpperCase() === 'VIC' && (
              <ButtonPrimary
                color="blue"
                shape="round"
                size="large"
                style={{ margin: '10px 0 20px 0' }}
                onClick={() =>
                  history.push({
                    pathname: '/formulari-acces',
                    state: { initialStep: 1, singlePage: true, username: currentUser.username },
                  })
                }
              >
                Solicitar accés a una nova zona
                <MdAdd style={{ verticalAlign: 'sub', fontSize: '20px', marginLeft: '5px' }} />
              </ButtonPrimary>
            )}

            <Flex vertical>
              <Flex vertical gap="middle">
                <Typography.Title level={4} style={{ margin: 0 }}>
                  {t('zone-authorizations.citizen.list.total-elements', { totalElements })}
                </Typography.Title>
                <Table
                  dataSource={zoneAuthorizations}
                  columns={columns}
                  rowKey={(record) => record.zoneId}
                  pagination={{
                    pageSize: 10,
                    position: ['none', 'none'],
                  }}
                  bordered
                />
              </Flex>
            </Flex>

            <Flex gap="middle" align="center" justify="center">
              <ButtonPrimary
                color="black"
                onClick={() => setCurrentPage((prevPage) => prevPage - 1)}
                shape="round"
                size="middle"
                disabled={!hasPreviousPage}
              >
                <LuArrowLeft fontSize={20} />
              </ButtonPrimary>

              <Typography.Text>
                {t('zone-authorizations.citizen.list.page-of', {
                  currentPage: currentPage + 1,
                  totalPages: totalPages,
                })}
              </Typography.Text>

              <ButtonPrimary
                color="black"
                onClick={() => setCurrentPage((prevPage) => prevPage + 1)}
                shape="round"
                size="middle"
                disabled={!hasNextPage}
              >
                <LuArrowRight fontSize={20} />
              </ButtonPrimary>
            </Flex>
          </Flex>
        </Card>
      </Spin>
    </div>
  );
};

export default CitizenZoneAuthorizationsList;
