import React, { useState, useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import { Spin } from 'antd';

import CustomFooter from 'components/CustomFooter.jsx';
import LoginPage from 'views/Login/LoginPage';
import ChangePasswordPage from 'views/Login/ChangePasswordPage';
import videoLogin from 'assets/img/videoLogin.mov';

import pagesRoutes from 'routes/pages.jsx';
import VerifiedEmail from 'views/VerifiedEmail/VerifiedEmail';
import { ACCESS_TOKEN } from 'util/Constants';
import VerifyMfaPage from 'views/Login/VerifyMfaPage';

const Pages = ({ location, history, loadCurrentUser, handleLoginForm, handleLogout }) => {
  const [videoLoading, setVideoLoading] = useState(true);

  const onChangePassword = (title, description) => {
    loadCurrentUser();
    history.push({
      pathname: '/pages/change-password',
      state: { title, description },
    });
  };

  const onMfaVerify = (userId, email, lastAccessAt) => {
    loadCurrentUser();
    let token = localStorage.getItem(ACCESS_TOKEN);
    localStorage.removeItem(ACCESS_TOKEN);
    history.push({
      pathname: '/pages/verify-mfa',
      state: {
        userId,
        email,
        token,
        center: localStorage.getItem('center'),
        lastAccessAt,
      },
    });
  };

  const spinStyle = {
    position: 'absolute',
    top: 0,
    bottom: 0,
    background: 'white',
    width: '100%',
    height: '100vh',
    zIndex: 5,
    padding: '100px',
  };

  return (
    <div>
      {videoLoading && <Spin style={spinStyle} />}
      <div>
        <div>
          <div>
            <video
              onLoadedData={() => setVideoLoading(false)}
              autoPlay
              loop
              muted
              style={{
                position: 'absolute',
                top: '0',
                left: '0',
                width: '100%',
                height: '100vh',
                objectFit: 'cover',
              }}
            >
              <source src={videoLogin} />
              Your browser does not support the video tag.
            </video>
          </div>

          <div>
            <Switch>
              <Route
                path="/login"
                render={(routeProps) => (
                  <LoginPage
                    onLogin={handleLoginForm}
                    onChangePassword={onChangePassword}
                    onMfaVerify={onMfaVerify}
                    {...routeProps}
                  />
                )}
              />

              <Route
                path="/pages/change-password"
                render={(routeProps) => (
                  <ChangePasswordPage
                    updatedPassword={handleLoginForm}
                    {...routeProps}
                  />
                )}
              />

              <Route
                path="/pages/verify-mfa"
                render={(routeProps) => (
                  <VerifyMfaPage
                    onLogin={handleLoginForm}
                    onChangePassword={onChangePassword}
                    {...routeProps}
                  />
                )}
              />

              <Route
                path="/pages/verify-email/:userId/:code"
                render={(routeProps) => (
                  <VerifiedEmail
                    toLogin={handleLogout}
                    {...routeProps}
                  />
                )}
              />

              {pagesRoutes.map((prop, key) => (
                <Route
                  path={prop.path}
                  component={prop.component}
                  key={key}
                />
              ))}
            </Switch>
          </div>
          <CustomFooter transparent />
        </div>
      </div>
    </div>
  );
};

export default Pages;
