import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import 'moment/locale/es';

import AddEmail from 'components/AddEmail.jsx';
import ButtonPrimary from 'components/Buttons/ButtonPrimary';
import colors from 'util/colors';

import * as UserRepository from '../../repository/user/UserRepository';

import { Spin, Descriptions, Form, Input, notification, Row, Space, Col, Card, Select, Tooltip, Switch } from 'antd';
import { LoadingOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { MdAccountCircle } from 'react-icons/md';

moment.locale('es');

const languages = [
  {
    value: 'es',
    label: 'ES - Español',
    disabled: false,
  },
  {
    value: 'ca',
    label: 'CA - Català',
    disabled: false,
  },
  {
    value: 'ga',
    label: 'GA - Galego',
    disabled: false,
  },
  {
    value: 'eus',
    label: 'EUS - Euskera',
    disabled: false,
  },
];

const Profile = (props) => {
  const { t } = useTranslation('common');
  const { currentUser } = props;

  const [loading, setLoading] = useState(false);
  const [switchLoading, setSwitchLoading] = useState(false);

  const antIcon = (
    <LoadingOutlined
      style={{ fontSize: 24 }}
      spin
    />
  );

  const changePassword = (values) => {
    setLoading(true);
    UserRepository.updatePassword(values.password)
      .then(() => {
        setLoading(false);
        notification.success({
          message: 'LPRConnect App',
          description: t('profile.update.success'),
        });
      })
      .catch((error) => {
        if (error.message === 'La contrasenya no pot ser la mateixa que la anterior') {
          notification.error({
            message: 'ZinkinData Portal',
            description: t('profile.password.same-password-error'),
          });
        } else {
          notification.error({
            message: 'ZinkinData Portal',
            description: t('profile.update.error'),
          });
        }
        setLoading(false);
      });
  };

  const error_validation_password_message = t('profile.password.password-pattern');
  const confirm_password_message = t('profile.password.confirm-password-error');

  return (
    <div>
      {(!currentUser.email || currentUser.email === '') && (
        <Row>
          <AddEmail {...props} />
        </Row>
      )}
      <Spin
        spinning={loading}
        indicator={antIcon}
      >
        <Space
          direction="vertical"
          style={{ width: '100%' }}
          size="large"
        >
          <Row>
            <Col
              xs={{ flex: '100%' }}
              sm={{ flex: '100%' }}
              md={{ flex: '50%' }}
              lg={{ flex: '50%' }}
              xl={{ flex: '50%' }}
            >
              <Card title={t('profile.me.title')}>
                <Descriptions
                  style={{ marginBottom: '13px' }}
                  size="middle"
                  bordered
                >
                  <Descriptions.Item
                    span={3}
                    label={t('profile.me.dni')}
                  >
                    {currentUser.username}
                  </Descriptions.Item>
                  <Descriptions.Item
                    span={3}
                    label={t('profile.me.name')}
                  >
                    {currentUser.name}
                  </Descriptions.Item>
                  <Descriptions.Item
                    span={3}
                    label={t('profile.me.surnames')}
                  >
                    {currentUser.surnames}
                  </Descriptions.Item>
                  <Descriptions.Item
                    span={3}
                    label={t('profile.me.email')}
                  >
                    {currentUser.email}
                  </Descriptions.Item>
                  <Descriptions.Item
                    span={3}
                    label={t('profile.me.phone')}
                  >
                    {currentUser.phone}
                  </Descriptions.Item>
                  <Descriptions.Item
                    span={3}
                    label={t('profile.me.language')}
                  >
                    <Select
                      size="large"
                      defaultValue={currentUser.language}
                      options={languages}
                      onChange={(value) => {
                        UserRepository.changeLanguage(localStorage.getItem('center'), value)
                          .then(() => {
                            window.location.reload();
                          })
                          .catch(() => {
                            notification.error({
                              message: 'LPRConnect App',
                              description: t('profile.update.error-language'),
                            });
                          });
                      }}
                    />
                  </Descriptions.Item>
                  {currentUser.email && (
                    <Descriptions.Item
                      span={3}
                      label={
                        <>
                          {t('profile.me.mfa')}
                          <Tooltip
                            title={t('profile.me.mfa-tooltip')}
                            placement="right"
                            color={colors.blue.main}
                          >
                            <InfoCircleOutlined style={{ marginLeft: '5px' }} />
                          </Tooltip>
                        </>
                      }
                    >
                      <Switch
                        loading={switchLoading}
                        defaultChecked={currentUser.mfa}
                        onClick={(checked) => {
                          setSwitchLoading(true);
                          UserRepository.changeMfa(localStorage.getItem('center'), checked)
                            .then(() => {
                              // You can add success handling if needed
                            })
                            .catch(() => {
                              // You can add error handling if needed
                            })
                            .finally(() => {
                              setSwitchLoading(false);
                            });
                        }}
                      />
                    </Descriptions.Item>
                  )}
                </Descriptions>
              </Card>
            </Col>
            <Col
              xs={{ flex: '100%' }}
              sm={{ flex: '100%' }}
              md={{ flex: '50%' }}
              lg={{ flex: '50%' }}
              xl={{ flex: '50%' }}
            >
              <Card title={t('profile.password.title')}>
                <Form
                  layout="vertical"
                  onFinish={changePassword}
                >
                  <Form.Item
                    name="password"
                    label={t('profile.password.new')}
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: t('profile.password.password-required'),
                      },
                      () => ({
                        validator(_, value) {
                          if (value.length < 6) {
                            return Promise.reject(new Error(error_validation_password_message));
                          }
                          const pattern = new RegExp(
                            '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&\\-_=+#~])[A-Za-z\\d@$!%*?&\\-_=+#~]+$'
                          );
                          if (!pattern.test(value)) {
                            return Promise.reject(new Error(error_validation_password_message));
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <Input.Password />
                  </Form.Item>

                  <Form.Item
                    name="confirm"
                    label={t('profile.password.confirm')}
                    dependencies={['password']}
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: t('profile.password.password-required'),
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue('password') === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error(confirm_password_message));
                        },
                      }),
                    ]}
                  >
                    <Input.Password />
                  </Form.Item>

                  <ButtonPrimary
                    color="black"
                    shape="round"
                    size="large"
                    htmlType="submit"
                    style={{ width: '100%' }}
                  >
                    {t('profile.password.update')}
                  </ButtonPrimary>
                </Form>
              </Card>
            </Col>
          </Row>
        </Space>
      </Spin>
    </div>
  );
};

export default Profile;
