import { request } from 'repository/apiUtils';
import { API_BASE_URL, buildOptionsWithAuth } from 'util/Constants.js';

export async function postWhitelistEntryCitizen(body) {
  const headers = buildOptionsWithAuth('POST');
  const url = API_BASE_URL + '/whitelist-entries';

  return fetch(url, {
    ...headers,
    body: JSON.stringify(body),
  }).then((response) => response);
}

export function expireWhitelistEntry(whitelistEntryId) {
  const headers = buildOptionsWithAuth('PUT');
  const url = `${API_BASE_URL}/whitelist-entries/${whitelistEntryId}/expire`;

  return fetch(url, headers).then((response) => {
    if (response.status !== 204) throw new Error('Error intern de servidor');
  });
}

export function getAllWhitelistEntriesFromCurrentUser() {
  return request({
    url: `${API_BASE_URL}/whitelist-entries/me`,
    method: 'GET',
  });
}

export function getWhitelistEntriesPaginated({ citizenId, licensePlate, status, pageNumber, pageSize }) {
  const queryParams = {
    ...(citizenId && { citizenId }),
    ...(licensePlate && { licensePlate }),
    ...(status && { status }),
    pageNumber: pageNumber || 0,
    pageSize: pageSize || 10,
  };

  const queryString = new URLSearchParams(queryParams).toString();

  return request({
    url: `${API_BASE_URL}/whitelist-entries/paginated?${queryString}`,
    method: 'GET',
  });
}
