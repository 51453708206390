export const PROFILE = process.env.NODE_ENV;
export const API_BASE_URL = PROFILE === 'development' ? 'http://localhost:8100/api' : 'https://zinkindata.com/api';
export const ACCESS_TOKEN = 'accessToken';

export const userRoles = {
  ROLE_USER: 'Ciutadà',
  ROLE_ADMIN: 'Administrador',
  ROLE_SUPERADMIN: 'Superadministrador',
};

export function buildOptionsWithAuth(method) {
  const headers = new Headers({
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
  });
  const options = {
    method,
    headers,
  };

  return options;
}

export function buildOptionsWithoutAuth(method) {
  const headers = new Headers({ 'Content-Type': 'application/json' });
  const options = {
    method,
    headers,
  };

  return options;
}
