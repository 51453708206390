import { API_BASE_URL, ACCESS_TOKEN, buildOptionsWithAuth, buildOptionsWithoutAuth } from 'util/Constants.js';

export async function changeLanguage(centerName, language) {
  const url = `${API_BASE_URL}/users/language?language=${language}`;
  const headers = buildOptionsWithAuth('PUT');
  const response = await fetch(url, headers);
  if (response.status !== 200) {
    throw new Error('Internal error');
  }
}

export async function changeMfa(centerName, mfa) {
  const url = `${API_BASE_URL}/users/mfa?value=${mfa}`;
  const headers = buildOptionsWithAuth('PUT');
  const response = await fetch(url, headers);
  if (response.status !== 200) {
    throw new Error('Internal error');
  }
}

export async function updatePassword(password) {
  const payload = {
    password: password,
  };

  const url = `${API_BASE_URL}/users/password`;
  const headers = buildOptionsWithAuth('PUT');

  const response = await fetch(url, Object.assign({}, headers, { body: JSON.stringify(payload) }));
  switch (response.status) {
    case 404:
      throw new Error('error-update-password');
    case 400:
      throw new Error('same-update-password');
    default:
      return;
  }
}

export function getCurrentUser() {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject('No access token set.');
  }

  const url = `${API_BASE_URL}/user-portals/me`;
  const headers = buildOptionsWithAuth('GET');

  return fetch(url, headers).then((response) => {
    if (response.status !== 200) throw new Error('Error intern de servidor');
    return response.json();
  });
}

export async function setEmailToUser(email) {
  const payload = {
    email: email,
  };

  const url = `${API_BASE_URL}/users/email?platform=PORTAL`;
  const headers = buildOptionsWithAuth('PATCH');

  const response = await fetch(url, Object.assign({}, headers, { body: JSON.stringify(payload) }));
  if (response.status !== 200) throw new Error('Error intern de servidor');
}

export async function resetPassword(email, centerName) {
  const url = `${API_BASE_URL}/users/reset-password`;
  let options = buildOptionsWithoutAuth('POST');
  const body = JSON.stringify({ email, centerName, platform: 'PORTAL' });

  const response = await fetch(url, { ...options, body });

  if (!response.ok) {
    if (response.status === 404) {
      throw new Error("No s'ha trobat cap usuari amb aquest email al sistema");
    }

    throw new Error(`Error codi http: ${response.status}`);
  }
}

export async function verifyEmail(userId, code) {
  const url = `${API_BASE_URL}/users/${userId}/verify?code=${code}&platform=PORTAL`;
  const headers = buildOptionsWithoutAuth('POST');

  const response = await fetch(url, headers);
  if (response.status !== 200) throw new Error('Error intern de servidor');
}
