import React, { useState, useEffect, useCallback } from 'react';
import { withTranslation } from 'react-i18next';
import { Grid, ConfigProvider, Flex, Layout, Menu, Space, Tooltip, Typography } from 'antd';
import { MdKeyboardArrowRight } from 'react-icons/md';
import CustomAvatar from './CustomAvatar';
import { userRoles } from 'util/Constants';
import dashboardRoutes from 'routes/dashboard.jsx';
import colors from '../util/colors';
import styles from './sidebar.module.css';
import zinkinDataLogo from 'assets/img/ZinkinData_100_25.png';
import defaultAvatar from 'assets/img/default-avatar.png';

const { Text } = Typography;
const { Sider } = Layout;
const { useBreakpoint } = Grid;

const Sidebar = ({ currentUser, currentCenter, location, history, collapse, setCollapse, t }) => {
  const [sidebarSections, setSidebarSections] = useState([]);
  const screens = useBreakpoint();
  const avatar = currentCenter.logo || defaultAvatar;

  const initMenuItems = useCallback(async () => {
    const createSection = (section) => {
      if (section.hidden) {
        return null;
      }

      return {
        label: t(section.name).toUpperCase(),
        key: section.path,
        icon: section.icon && React.createElement(section.icon, { style: { fontSize: 22 } }),
      };
    };

    const sidebarSections = dashboardRoutes.map(createSection);
    setSidebarSections(sidebarSections);
  }, [t, location.pathname]);

  useEffect(() => {
    initMenuItems();
  }, [initMenuItems]);

  const renderProfile = () => {
    return (
      <Space
        align="center"
        direction="vertical"
        style={{ width: '100%', padding: '15px', marginBottom: '-10%' }}
        onClick={() => history.push('/profile')}
      >
        <CustomAvatar src={avatar} />
        <Tooltip
          title={t('sidebar.see-profile')}
          placement="right"
          color={colors.blue.main}
        >
          <Space
            align="center"
            className={styles.editProfile}
            style={{ color: 'white', fontSize: '20px' }}
          >
            <span>{currentUser.name}</span>
            {currentUser.surnames && <span style={{ fontWeight: 'bold' }}>{currentUser.surnames.split(' ')[0]}</span>}
            <MdKeyboardArrowRight style={{ fontSize: '20px', verticalAlign: 'middle' }} />
          </Space>
        </Tooltip>
        <Text style={{ color: '#AAA', fontSize: '15px' }}>{userRoles[currentUser.role]}</Text>
      </Space>
    );
  };

  return (
    <Sider
      breakpoint="lg"
      collapsedWidth="0"
      trigger={null}
      collapsed={collapse}
      onCollapse={(value) => setCollapse(value)}
      width={'270px'}
      style={{
        height: '100vh',
        overflow: 'auto',
        position: 'fixed',
        backgroundColor: '#222',
      }}
    >
      <Flex
        style={{ width: '255px' }}
        justify="center"
      >
        <img
          src={zinkinDataLogo}
          style={{
            width: '60%',
            margin: '15px 0px',
            filter: 'drop-shadow(5px 5px 3px #151515)',
          }}
          alt="zinkindata-logo"
        />
      </Flex>

      <Flex
        justify="center"
        align="center"
        vertical
        gap={5}
        className="editProfile"
        style={{ width: '250px' }}
        onClick={() => {
          if (!screens.lg) {
            setCollapse(true);
          }
        }}
      >
        {renderProfile()}

        <ConfigProvider
          theme={{
            components: {
              Menu: {
                darkItemBg: '#222',
                darkSubMenuItemBg: '#111',
                darkItemSelectedBg: '#0064ad',
                itemSelectedColor: '#0064ad',
                itemHoverColor: 'red',
              },
            },
          }}
        >
          <Menu
            theme="dark"
            mode="inline"
            items={sidebarSections}
            style={{ fontSize: '13px', fontWeight: 600, marginTop: '15px' }}
            onClick={({ key }) => {
              history.push(key);
              if (!screens.lg) {
                setCollapse(true);
              }
            }}
          />
        </ConfigProvider>
      </Flex>
    </Sider>
  );
};

export default withTranslation('common')(Sidebar);
