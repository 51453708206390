import LicensePlates from 'views/LicensePlates/LicensePlates';
import LicensePlatesCreate from 'views/LicensePlates/LicensePlatesCreate';
import Profile from 'views/Profile/Profile';
import CitizenZoneAuthoritzationsList from 'views/ZoneAuthoritzations/CitizenZoneAuthoritzationsList';

import { MdAccountCircle, MdDirectionsCarFilled, MdLocationOn } from 'react-icons/md';

var dashboardRoutes = [
  {
    path: '/profile',
    name: 'sidebar.profile',
    icon: MdAccountCircle,
    component: Profile,
    roles: ['ROLE_CITIZEN'],
  },
  {
    path: '/license-plates',
    name: 'sidebar.license-plate',
    icon: MdDirectionsCarFilled,
    component: LicensePlates,
    roles: ['ROLE_CITIZEN'],
  },
  {
    hidden: true,
    path: '/license-plates-add',
    name: 'sidebar.admin-add-plates',
    component: LicensePlatesCreate,
  },
  {
    path: '/citizen-authoritzations',
    name: 'sidebar.zone-authorizations',
    icon: MdLocationOn,
    component: CitizenZoneAuthoritzationsList,
    roles: ['ROLE_CITIZEN'],
  },
];

export default dashboardRoutes;
