import { ACCESS_TOKEN } from '../util/Constants';

export const request = (options) => {
  const headers = new Headers({
    'Content-Type': 'application/json',
  });

  if (localStorage.getItem(ACCESS_TOKEN)) {
    headers.append('Authorization', 'Bearer ' + localStorage.getItem(ACCESS_TOKEN));
  }

  const defaults = { headers: headers };
  options = Object.assign({}, defaults, options);

  return fetch(options.url, options)
    .then((response) =>
      response.ok
        ? response.json().then((json) => {
            if (!response.ok) {
              return Promise.reject(json);
            }
            return json;
          })
        : response.status === 409
        ? response.text().then((json) => Promise.reject(json))
        : response
    )
    .catch((error) => {
      return Promise.reject(error);
    });
};
