export function validateIdentificationNumber(identificationNumber) {
  var dniRegex = /^\d{8}[a-zA-Z]$/;
  var passportRegex = /^[a-zA-Z]{2,3}\d{6,7}$/;
  var nifRegex = /^[a-zA-Z]\d{7}[a-zA-Z]$/;
  var nieRegex = /^[a-zA-Z]\d{7}[a-zA-Z]$/;
  var cifRegex = /^([ABCDEFGHJKLMNPQRSUVW])(\d{7})([0-9A-J])$/;

  if (dniRegex.test(identificationNumber)) {
    var numero = parseInt(identificationNumber.substring(0, 8), 10);
    var letra = identificationNumber.charAt(8).toUpperCase();
    var letras = 'TRWAGMYFPDXBNJZSQVHLCKE';
    if (letra === letras.charAt(numero % 23)) {
      return true;
    } else {
      return false;
    }
  }

  if (passportRegex.test(identificationNumber)) {
    return true;
  }

  if (nifRegex.test(identificationNumber)) {
    return true;
  }

  if (nieRegex.test(identificationNumber)) {
    return true;
  }

  if (cifRegex.test(identificationNumber)) {
    return true;
  }

  return false;
}
