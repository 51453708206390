import { useEffect } from 'react';

const LogoutView = (props) => {
  useEffect(() => {
    props.handleLogout();
  }, [props.handleLogout]);

  return null;
};

export default LogoutView;
