import { request } from 'repository/apiUtils';
import { API_BASE_URL } from 'util/Constants';

export function getZoneAuthorizationsFromCurrentUser() {
  return request({
    url: `${API_BASE_URL}/zone-authorizations/me`,
    method: 'GET',
  });
}

export function getZoneAuthorizationsPaginated({ citizenId, pageNumber, pageSize }) {
  return request({
    url: `${API_BASE_URL}/zone-authorizations/paginated?citizenId=${citizenId}&pageNumber=${pageNumber}&pageSize=${pageSize}`,
    method: 'GET',
  });
}
