import React, { useEffect, useState } from 'react';
import { LoadingOutlined, InfoCircleOutlined } from '@ant-design/icons';
import {
  Card,
  ConfigProvider,
  DatePicker,
  Form,
  Input,
  Modal,
  notification,
  Radio,
  Select,
  Space,
  Spin,
  Tooltip,
} from 'antd';
import { MdDirectionsCar } from 'react-icons/md';
import dayjs from 'dayjs';
import moment from 'moment';
import 'dayjs/locale/es';
import 'dayjs/locale/ca';
import 'moment/locale/es';
import 'moment/locale/ca';
import { withTranslation } from 'react-i18next';
import esES from 'antd/locale/es_ES';
import caES from 'antd/locale/ca_ES';
import { postWhitelistEntryCitizen } from 'repository/whitelistEntries/WhitelistEntryRepository';

import ButtonPrimary from 'components/Buttons/ButtonPrimary.jsx';
import colors from 'util/colors';
import { getZoneAuthorizationsFromCurrentUser } from 'repository/zoneAuthorization/ZoneAuthorizationRepository';

const { TextArea } = Input;
const Option = Select.Option;

const LicensePlatesCreate = ({ currentUser, history, t }) => {
  const [zarZones, setZarZones] = useState([]);
  const [filteredZones, setFilteredZones] = useState([]);
  const [typeSelected, setTypeSelected] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [loading, setLoading] = useState(true);
  const [reasonOption, setReasonOption] = useState(0);
  const [customReason, setCustomReason] = useState('');

  const reasons = [
    t('license-plates.citizen.reasons.charge'),
    t('license-plates.citizen.reasons.delivery'),
    t('license-plates.citizen.reasons.reduced-mobility'),
  ];

  useEffect(fetchZones, []);

  function fetchZones() {
    getZoneAuthorizationsFromCurrentUser()
      .then((response) => {
        if (response.length === 0) {
          Modal.warning({
            title: t('license-plates.citizen.create.no-zones-modal.title'),
            content: t('license-plates.citizen.create.no-zones-modal.message'),
            onOk: () => history.push('/license-plates'),
            okText: t('license-plates.citizen.create.no-zones-modal.confirm-button'),
            centered: true,
          });
        }

        setZarZones(response);
        filterZones(response);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }

  async function filterZones(zones) {
    try {
      // no se pueden agregar matriculas de 1 dia en autorizaciones de zona con esta razon
      const exclusionReason = 'Necessito un accés causal (accés màxim de 3 mesos)';
      const filteredZones = zones.filter((zone) => zone.reason !== exclusionReason);
      setFilteredZones(filteredZones);
    } catch (error) {
      console.log(error);
    }
  }

  const zarZonesOptions = filteredZones.map((zone) => {
    return (
      <Option
        disabled={zone.validity === 'DENIED'}
        key={zone.zoneId}
      >
        {zone.zoneName}
      </Option>
    );
  });

  const onFinish = (values) => {
    setLoading(true);

    let finalReason;
    switch (reasonOption) {
      case 0:
        finalReason = undefined;
        break;
      case 4:
        finalReason = customReason;
        break;
      default:
        finalReason = reasons[reasonOption - 1];
        break;
    }

    const payload = {
      citizenId: currentUser.id,
      licensePlate: values.plate,
      startDate: moment(values.date.$d).format('YYYY-MM-DD').toString(),
      finishDate: moment(values.date.$d).format('YYYY-MM-DD').toString(),
      type: 'DAILY',
      reason: finalReason,
      zoneIds: [Number(values.zone)],
    };

    postWhitelistEntryCitizen(payload).then(async (response) => {
      if (response.status === 409) {
        notification['error']({
          message: 'LPR Connect',
          duration: 5,
          description: t('license-plates.citizen.create.already-exist'),
        });
      } else if (response.status === 429) {
        notification.error({
          message: 'ZinkinData Portal',
          duration: 5,
          description: t('license-plates.citizen.create.too-many-requests'),
        });
      } else if (response.status === 422) {
        let body = await response.text();
        if (body.includes('ON HOLD')) {
          notification.error({
            message: 'ZinkinData Portal',
            duration: 5,
            description: t('license-plates.citizen.create.max-on-hold-entries'),
          });
        } else {
          notification.error({
            message: 'ZinkinData Portal',
            duration: 5,
            description: t('license-plates.citizen.create.date-out-of-range'),
          });
        }
      } else if (response.status === 201) {
        notification['success']({
          message: 'LPR Connect',
          description: t('license-plates.citizen.create.success'),
        });

        history.push('/license-plates');
      } else {
        notification['error']({
          message: 'LPR Connect',
          description: t('license-plates.citizen.create.error'),
        });
      }
      setLoading(false);
    });
  };

  return (
    <Spin
      spinning={loading}
      indicator={
        <LoadingOutlined
          style={{ fontSize: 24 }}
          spin
        />
      }
    >
      <Card title={t('license-plates.citizen.create.title')}>
        <Form
          name="basic"
          initialValues={{ remember: true }}
          autoComplete="off"
          size="large"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 8 }}
          onFinish={onFinish}
        >
          <Form.Item
            label={t('license-plates.admin.create.form.zone')}
            name="zone"
            rules={[
              {
                required: true,
                message: t('license-plates.admin.create.form.required-zone'),
              },
            ]}
          >
            <Select
              value={zarZones}
              size="large"
              placeholder={t('license-plates.admin.create.form.zone-placeholder')}
            >
              {zarZonesOptions}
            </Select>
          </Form.Item>
          <Form.Item
            label={t('license-plates.admin.create.form.plate')}
            name="plate"
            rules={[
              {
                required: true,
                message: t('license-plates.admin.create.form.required-plate'),
              },
              {
                pattern: new RegExp(/^[A-Z0-9]+$/),
                message: t('license-plates.admin.create.form.plate-placeholder'),
              },
            ]}
          >
            <Input
              placeholder="Ex: 1234ABC"
              suffix={
                <Tooltip
                  title={t('license-plates.admin.create.form.plate-placeholder')}
                  placement="right"
                  color={colors.blue.main}
                >
                  <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                </Tooltip>
              }
            />
          </Form.Item>
          <Form.Item
            label={t('license-plates.admin.create.form.time')}
            name="type"
            rules={[
              {
                required: true,
                message: t('license-plates.admin.create.form.required-time'),
              },
            ]}
          >
            <Radio.Group
              size="large"
              optionType="button"
              buttonStyle="solid"
              value={typeSelected}
              onChange={(event) => {
                setTypeSelected(event.target.value);
                setStartDate(null);
              }}
            >
              <Tooltip
                placement="bottom"
                title={t('license-plates.citizen.create.form.day-tooltip')}
                color={colors.blue.main}
              >
                <Radio value={'DAILY'}>{t('license-plates.citizen.create.form.day')}</Radio>
              </Tooltip>
              <Tooltip
                placement="bottom"
                title={t('license-plates.citizen.create.form.permanent-time-tooltip')}
                color={colors.blue.main}
              >
                <Radio
                  disabled={true}
                  value={'PERMANENT'}
                >
                  {t('license-plates.citizen.create.form.permanent-time')}
                </Radio>
              </Tooltip>
              <Tooltip
                placement="bottom"
                title={t('license-plates.citizen.create.form.custom-time-tooltip')}
                color={colors.blue.main}
              >
                <Radio
                  disabled={true}
                  value={'CUSTOM'}
                >
                  {t('license-plates.citizen.create.form.custom-time')}
                </Radio>
              </Tooltip>
            </Radio.Group>
          </Form.Item>
          {typeSelected !== '' && (
            <ConfigProvider locale={currentUser.language === 'ca' ? caES : esES}>
              <Form.Item
                label={t('license-plates.admin.create.form.calendar-placeholder')}
                name="date"
                rules={[
                  {
                    required: true,
                    message: 'És obligatori',
                  },
                ]}
              >
                <DatePicker
                  placeholder={t('license-plates.admin.create.form.calendar-placeholder-1')}
                  style={{ width: '100%' }}
                  disabledDate={(current) => {
                    const marginDays = localStorage.getItem('center').toUpperCase() === 'TORELLO' ? 3 : 6;
                    return (
                      current.isBefore(moment().subtract(marginDays, 'day')) || current.isAfter(moment().add(14, 'day'))
                    );
                  }}
                  size="large"
                  value={startDate === null ? null : dayjs(startDate)}
                  onChange={(event) => {
                    setStartDate(event.$d);
                  }}
                  format={'DD/MM/YYYY'}
                />
              </Form.Item>
            </ConfigProvider>
          )}
          {localStorage.getItem('center').toUpperCase() === 'TORELLO' ? (
            <Form.Item
              label={t('license-plates.citizen.create.form.reason')}
              name="reason"
              rules={[
                {
                  required: true,
                  message: t('license-plates.admin.create.form.plate'),
                },
              ]}
            >
              <Radio.Group
                size="large"
                onChange={(event) => setReasonOption(event.target.value)}
              >
                <Space direction="vertical">
                  <Radio value={1}>{reasons[0]}</Radio>
                  <Radio value={2}>{reasons[1]}</Radio>
                  <Radio value={3}>{reasons[2]}</Radio>
                  <Radio value={4}>{t('license-plates.citizen.create.form.other-reason')}</Radio>
                  {reasonOption === 4 ? (
                    <TextArea
                      onChange={(event) => setCustomReason(event.target.value)}
                      value={customReason}
                    />
                  ) : null}
                </Space>
              </Radio.Group>
            </Form.Item>
          ) : null}

          <Form.Item
            label=" "
            colon={false}
          >
            <ButtonPrimary
              color="blue"
              shape="round"
              size="large"
              htmlType="submit"
              style={{ width: '100%' }}
            >
              {t('license-plates.admin.add-button')}
            </ButtonPrimary>
          </Form.Item>
        </Form>
      </Card>
    </Spin>
  );
};

export default withTranslation('common')(LicensePlatesCreate);
