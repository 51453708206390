import React, { useState } from 'react';
import { ACCESS_TOKEN } from '../../util/Constants';
import * as AuthRepository from 'repository/auth/AuthRepository';
import * as UserRepository from 'repository/user/UserRepository';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { Form, Input, Spin, Modal, notification } from 'antd';
import colors from 'util/colors';
import { LoadingOutlined } from '@ant-design/icons';
import { MdPerson, MdPassword, MdEmail } from 'react-icons/md';
import { LuCalendarCheck2 } from 'react-icons/lu';
import ButtonPrimary from 'components/Buttons/ButtonPrimary';

const { Item: FormItem } = Form;

const LoginForm = ({ history, onChangePassword, onMfaVerify, onLogin, t }) => {
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalEmail, setModalEmail] = useState('');

  const resetPassword = async () => {
    setLoading(true);
    setModalVisible(false);

    try {
      const pathVariables = history.location.pathname.split('/');
      const center =
        pathVariables[pathVariables.length - 1] === 'login' ? 'nexotech' : pathVariables[pathVariables.length - 1];
      await UserRepository.resetPassword(modalEmail, center);

      notification.success({
        message: 'ZinkinData Portal',
        description: t('login.password-sended'),
      });

      setModalEmail('');
    } catch (error) {
      notification.error({
        message: 'ZinkinData Portal',
        description: error.message,
      });
    }
    setLoading(false);
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    const pathVariables = history.location.pathname.split('/');
    const lastWord = pathVariables[pathVariables.length - 1];
    const center = lastWord === 'login' ? 'nexotech' : lastWord;

    const request = {
      center,
      username: values.username,
      password: values.password,
      platform: 'PORTAL',
    };

    try {
      const response = await AuthRepository.login(request);
      const body = await response.json();

      if (response.ok) {
        return handleSuccessfulLogin(body);
      }

      handleLoginError(body);
    } catch (error) {
      notification.error({
        message: 'Error',
        description: error.message,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSuccessfulLogin = async (body) => {
    localStorage.setItem(ACCESS_TOKEN, body.accessToken);

    try {
      const responseUser = await UserRepository.getCurrentUser();

      if (responseUser.firstLogin) {
        return onChangePassword(
          'change-password-page.first-login.title',
          'change-password-page.first-login.description'
        );
      }

      if (responseUser.mfa) {
        return onMfaVerify(responseUser.id, responseUser.email, body.lastAccessAt);
      }

      if (responseUser.passwordExpired) {
        return onChangePassword(
          'change-password-page.password-expire.title',
          'change-password-page.password-expire.description'
        );
      }

      onLogin();

      if (body.lastAccessAt) {
        notification.info({
          message: t('login.last-access'),
          description: moment(body.lastAccessAt).format('DD-MM-YYYY HH:mm:ss'),
          icon: <LuCalendarCheck2 style={{ color: '#108ee9' }} />,
          duration: 5,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleLoginError = (body) => {
    if (!body.errorCode) {
      return notification.error({ message: 'Error', description: 'Internal Server Error' });
    }

    const errorMessages = {
      AUTHENTICATION_ERROR: {
        message: t('login.incorrect-credentials'),
        description: body.attemptsLeft > 0 ? `${t('login.before-block')}${body.attemptsLeft}` : t('login.user-blocked'),
      },
      USER_BLOCKED: {
        message: t('login.user-blocked'),
        description: t('login.admin-contact'),
      },
      NON_VERIFIED_USER: {
        message: t('login.non-verified-user'),
        description: t('login.verify-email'),
      },
      BAD_CREDENTIALS_ERROR: {
        message: t('login.incorrect-credentials'),
        description: t('login.retry-credentials'),
      },
    };

    const errorMessage = errorMessages[body.errorCode] || {
      message: 'ZinkinData Portal',
      description: 'Internal server error',
    };

    notification.error(errorMessage);
  };

  const iconStyles = { fontSize: 22, color: '#0085cb' };
  const loadingIcon = <LoadingOutlined />;

  return (
    <Spin
      spinning={loading}
      indicator={loadingIcon}
    >
      <Form onFinish={handleSubmit}>
        <FormItem
          name="username"
          rules={[
            {
              required: true,
              message: t('login.required-username'),
            },
          ]}
        >
          <Input
            suffix={<MdPerson style={iconStyles} />}
            size="large"
            placeholder={t('login.username')}
            name="username"
          />
        </FormItem>
        <FormItem
          name="password"
          rules={[
            {
              required: true,
              message: t('login.required-password'),
            },
          ]}
        >
          <Input
            size="large"
            type="password"
            placeholder={t('login.password')}
            name="password"
            suffix={<MdPassword style={iconStyles} />}
          />
        </FormItem>
        <FormItem>
          <ButtonPrimary
            color="blue"
            htmlType="submit"
            size="large"
            block
            shape="round"
          >
            {t('login.login')}
          </ButtonPrimary>
          <p style={{ textAlign: 'center', marginTop: '20px', marginBottom: '5px' }}>
            <span
              onClick={() => setModalVisible(true)}
              style={{ color: '#0064ad', fontSize: '16px', cursor: 'pointer' }}
            >
              {t('login.forgot-password')}
            </span>
          </p>
        </FormItem>
      </Form>
      <Modal
        title={t('login.forgot-password')}
        open={modalVisible}
        onOk={resetPassword}
        onCancel={() => setModalVisible(false)}
        okText={t('login.recover-button')}
        cancelText={t('login.cancel-button')}
        okButtonProps={{
          style: { backgroundColor: colors.blue.main, borderColor: colors.blue.main },
        }}
      >
        <Form>
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: t('login.required-email'),
              },
              {
                type: 'email',
                message: t('login.bad-email'),
              },
            ]}
          >
            <Input
              suffix={<MdEmail />}
              size="large"
              placeholder={t('login.email')}
              value={modalEmail}
              onChange={(e) => setModalEmail(e.target.value)}
            />
          </Form.Item>
        </Form>
      </Modal>
    </Spin>
  );
};

export default withTranslation('common')(LoginForm);
