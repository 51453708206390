import { API_BASE_URL, buildOptionsWithAuth, buildOptionsWithoutAuth } from 'util/Constants.js';

export function login(loginRequest) {
  const url = `${API_BASE_URL}/auth/login`;
  const headers = buildOptionsWithoutAuth('POST');

  return fetch(url, { ...headers, body: JSON.stringify(loginRequest) });
}

export async function getCenter(name) {
  const url = `${API_BASE_URL}/centers/${name}`;
  const headers = buildOptionsWithAuth('GET');

  const response = await fetch(url, headers);
  if (response.status === 200) {
    const data = await response.json();
    return data;
  } else {
    throw new Error('Error intern de servidor');
  }
}

export async function verifyMfa(userId, code) {
  const url = `${API_BASE_URL}/auth/mfa/${userId}?code=${code}&platform=PORTAL`;
  const headers = buildOptionsWithoutAuth('PUT');

  const response = await fetch(url, headers);
  if (response.status !== 200) throw new Error(response.status);
}

export async function sendVerificationCode(userEmail, code, centerName) {
  const url = `${API_BASE_URL}/user-portals/verify/send-verification-code?center-name=${centerName}&user-email=${userEmail}&code=${code}`;
  const options = buildOptionsWithoutAuth('POST');

  let response;

  try {
    response = await fetch(url, options);
  } catch (error) {
    throw new Error(error);
  }

  if (response.status !== 200) {
    throw new Error(response.status);
  }
}
