import React, { useCallback, useState, useEffect, useRef } from 'react';
import moment from 'moment';
import 'moment/locale/es';
import { FilterOutlined, LoadingOutlined, SearchOutlined } from '@ant-design/icons';
import { Spin, Table, Tag, notification, Input, Space, Tooltip, Flex, Card, Modal, Typography, Radio } from 'antd';
import Button from 'antd-button-color';
import { MdAdd } from 'react-icons/md';
import { FaCircle } from 'react-icons/fa';
import { TbTrashXFilled } from 'react-icons/tb';
import { withTranslation } from 'react-i18next';
import {
  expireWhitelistEntry,
  getWhitelistEntriesPaginated,
} from 'repository/whitelistEntries/WhitelistEntryRepository';
import { getZoneAuthorizationsFromCurrentUser } from 'repository/zoneAuthorization/ZoneAuthorizationRepository';
import AddEmail from 'components/AddEmail';
import ButtonPrimary from 'components/Buttons/ButtonPrimary';
import colors from 'util/colors';
import { LuArrowLeft, LuArrowRight } from 'react-icons/lu';

moment.locale('es');

const LicensePlates = (props) => {
  const [loading, setLoading] = useState(true);
  const [zoneAuthorizations, setZoneAuthorizations] = useState([]);
  const [authorizedZones, setAuthorizedZones] = useState([]);
  const [data, setData] = useState([]);
  const [alert, setAlert] = useState(null);
  const [licensePlateFilter, setLicensePlateFilter] = useState(undefined);
  const [statusFilter, setStatusFilter] = useState(undefined);
  const searchInput = useRef(null);

  const [currentPage, setCurrentPage] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPreviousPage, setHasPreviousPage] = useState(false);

  const columns = [
    {
      title: props.t('license-plates.citizen.list.table.validity'),
      dataIndex: 'validity',
      key: 'validity',
      align: 'center',
      render: (_, record) => {
        const validityTags = {
          APPROVED: (
            <Tooltip placement="top" title={props.t('license-plates.validity.approved')} color={colors.blue.main}>
              <FaCircle style={{ color: '#28a745' }} />
            </Tooltip>
          ),
          PENDING: (
            <Tooltip placement="top" title={props.t('license-plates.validity.pending')} color={colors.blue.main}>
              <FaCircle style={{ color: 'orange' }} />
            </Tooltip>
          ),
          DENIED: (
            <Tooltip placement="top" title={props.t('license-plates.validity.denied')} color={colors.blue.main}>
              <FaCircle style={{ color: 'red' }} />
            </Tooltip>
          ),
        };

        return validityTags[record.validity] || <div></div>;
      },
    },
    {
      title: props.t('license-plates.citizen.list.table.zone'),
      dataIndex: 'zone',
      key: 'zone',
    },
    {
      title: props.t('license-plates.citizen.list.table.plate'),
      dataIndex: 'licensePlate',
      key: 'licensePlate',
      filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
      ...getLicensePlateColumnFilterProps('licensePlate'),
    },
    {
      title: props.t('license-plates.citizen.list.table.status'),
      dataIndex: 'status',
      key: 'status',
      render: (text, record) => {
        const startDate = moment(record.startDate);
        const endDate = moment(record.endDate);
        const now = moment();

        if (startDate.isAfter(now) && endDate.isAfter(startDate)) {
          return <Tag color="orange">{props.t('license-plates.status.on-hold')}</Tag>;
        } else if (record.status === 'VALID') {
          return <Tag color="green">{props.t('license-plates.status.valid')}</Tag>;
        } else {
          return <Tag color="red">{props.t('license-plates.status.expired')}</Tag>;
        }
      },
      filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
      ...getStatusColumnFilterProps('status'),
      filteredValue: statusFilter ? [statusFilter] : null,
    },
    {
      title: props.t('license-plates.citizen.list.table.created-date'),
      dataIndex: 'createdDate',
      key: 'createdDate',
      sorter: (a, b) => moment(a.createdDate) - moment(b.createdDate),
      defaultSortOrder: 'descend',
      render: (text, record) => {
        if (record.createdDate !== undefined && record.createdDate !== null)
          return moment(record.createdDate).format('DD-MM-YYYY HH:mm:ss');
      },
    },
    {
      title: props.t('license-plates.citizen.list.table.start-date'),
      dataIndex: 'startDate',
      key: 'startDate',
      render: (text, record) => {
        if (record.startDate !== undefined && record.startDate !== null)
          return moment(record.startDate).format('DD-MM-YYYY HH:mm:ss');
      },
    },
    {
      title: props.t('license-plates.citizen.list.table.end-date'),
      dataIndex: 'endDate',
      key: 'endDate',
      render: (text, record) => {
        if (record.endDate !== undefined && record.endDate !== null)
          return moment(record.endDate).format('DD-MM-YYYY HH:mm:ss');
      },
    },
    {
      title: props.t('license-plates.citizen.list.table.reason'),
      dataIndex: 'reason',
      align: 'center',
    },
  ];

  function getLicensePlateColumnFilterProps(dataIndex) {
    return {
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <Flex vertical style={{ padding: '10px' }} gap="small">
          <Input
            ref={searchInput}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          />

          <Flex gap="small">
            <ButtonPrimary
              color="blue"
              shape="round"
              size="small"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            >
              <SearchOutlined /> {props.t('license-plates.citizen.search-button')}
            </ButtonPrimary>
            <ButtonPrimary
              color="black"
              shape="round"
              size="small"
              onClick={() => {
                handleReset(clearFilters);
                setSelectedKeys([]);
                confirm();
              }}
            >
              {props.t('license-plates.citizen.reset-button')}
            </ButtonPrimary>
          </Flex>
        </Flex>
      ),
    };
  }

  function getStatusColumnFilterProps() {
    return {
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <Flex vertical style={{ padding: '10px' }} gap="small">
          <Radio.Group
            value={selectedKeys[0] || ''}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}
          >
            <Radio value="VALID">{props.t('license-plates.status.valid')}</Radio>
            <Radio value="EXPIRED">{props.t('license-plates.status.expired')}</Radio>
            <Radio value="ON_HOLD">{props.t('license-plates.status.on-hold')}</Radio>
          </Radio.Group>

          <Flex gap="small" style={{ marginTop: '10px' }}>
            <ButtonPrimary
              color="blue"
              shape="round"
              size="small"
              onClick={() => {
                handleStatusSearch(selectedKeys, confirm);
              }}
            >
              <SearchOutlined /> {props.t('license-plates.citizen.search-button')}
            </ButtonPrimary>
            <ButtonPrimary
              color="black"
              shape="round"
              size="small"
              onClick={() => {
                handleStatusReset(clearFilters);
                setSelectedKeys([]);
                confirm();
              }}
            >
              {props.t('license-plates.citizen.reset-button')}
            </ButtonPrimary>
          </Flex>
        </Flex>
      ),
      filterIcon: (filtered) => <FilterOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    };
  }

  const expireColumn = {
    title: props.t('license-plates.citizen.list.table.expire'),
    dataIndex: 'acciones',
    width: '150px',
    align: 'center',
    render: (_, record) => {
      const endDate = moment(record.endDate);
      const now = moment();

      const isNotExpired = endDate.isAfter(now);
      const isNotPermanent = record.endDate !== undefined && record.endDate !== null;

      if (isNotPermanent && isNotExpired) {
        return (
          <Button
            size="small"
            onClick={() => warningWithConfirmAndCancelMessage(record.id)}
            type={'primary'}
            danger
            with="link"
            width="50%"
          >
            <TbTrashXFilled style={{ fontSize: '25' }} />
          </Button>
        );
      }

      return null;
    },
  };

  const fetchZoneAuthorizations = useCallback(async () => {
    try {
      const zoneAuthorizations = await getZoneAuthorizationsFromCurrentUser();
      const authorizedZones = zoneAuthorizations.map((zoneAuthorization) => ({
        id: zoneAuthorization.zoneId,
        name: zoneAuthorization.zoneName,
        reason: zoneAuthorization.reason,
        validity: zoneAuthorization.validity,
      }));

      setZoneAuthorizations(zoneAuthorizations);
      setAuthorizedZones(authorizedZones);
    } catch (error) {
      notification.error({
        message: 'ZinkinData Portal',
        description: props.t('Hi ha hagut un error al carregar les dades'),
      });
    }
  }, []);

  const updateTable = useCallback(async () => {
    setLoading(true);

    try {
      const responseBody = await getWhitelistEntriesPaginated({
        citizenId: props.currentUser.id,
        pageNumber: currentPage,
        pageSize: 10,
        licensePlate: licensePlateFilter,
        status: statusFilter,
      });

      setTotalElements(responseBody.totalElements);
      setTotalPages(responseBody.totalPages);
      setHasNextPage(responseBody.totalPages > currentPage + 1);
      setHasPreviousPage(currentPage != 0);

      var whitelistEntries = responseBody.items.map((item) => {
        const zoneName = authorizedZones.find((authorizedZone) => authorizedZone.id === item.zone)?.name;
        return {
          key: item.id,
          id: item.id,
          zone: zoneName,
          licensePlate: item.licensePlate,
          status: item.status,
          validity: item.validity,
          createdDate: item.createdDate,
          startDate: item.startDate,
          endDate: item.finishDate,
          reason: item.reason,
        };
      });

      setData(whitelistEntries);
    } catch (error) {
      notification.error({
        message: 'ZinkinData Portal',
        description: props.t('Hi ha hagut un error al carregar les matrícules'),
      });
    } finally {
      setLoading(false);
    }
  }, [currentPage, licensePlateFilter, statusFilter, authorizedZones]);

  function userHasNonTrimestralAccess() {
    const expectedReason = 'Necessito un accés causal (accés màxim de 3 mesos)';

    if (!Array.isArray(zoneAuthorizations)) {
      return false;
    }

    return zoneAuthorizations.some((zoneAuthorization) => zoneAuthorization.reason !== expectedReason);
  }

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setLicensePlateFilter(selectedKeys[0]);
    setCurrentPage(0);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setLicensePlateFilter('');
    setCurrentPage(0);
  };

  const handleStatusSearch = (selectedKeys, confirm) => {
    confirm();
    setStatusFilter(selectedKeys[0]);
    setCurrentPage(0);
  };

  const handleStatusReset = (clearFilters) => {
    clearFilters();
    setStatusFilter('');
    setCurrentPage(0);
  };

  function warningWithConfirmAndCancelMessage(whitelistEntryId) {
    Modal.confirm({
      title: props.t('license-plates.citizen.list.expire-modal.title'),
      okText: props.t('license-plates.citizen.list.expire-modal.confirm-button'),
      onOk: () => successExpire(whitelistEntryId),
      cancelText: props.t('license-plates.citizen.list.expire-modal.cancel-button'),
      centered: true,
    });
  }

  async function expireWhitelistEntryRequest(whitelistEntryId) {
    setLoading(true);

    try {
      await expireWhitelistEntry(whitelistEntryId);

      hideAlert();
      notification.success({
        message: 'ZinkinData Portal',
        description: props.t('license-plates.citizen.expire-success'),
      });

      updateTable();
      setLoading(false);
    } catch (error) {
      notification.error({
        message: 'ZinkinData Portal',
        description: props.t('license-plates.citizen.expire-error'),
      });
    } finally {
      setLoading(false);
    }
  }

  function successExpire(whitelistEntryId) {
    expireWhitelistEntryRequest(whitelistEntryId);
  }

  function hideAlert() {
    setAlert(null);
  }

  useEffect(() => {
    fetchZoneAuthorizations();
  }, []);

  useEffect(() => {
    updateTable();
  }, [updateTable]);

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  return (
    <div>
      {alert}
      <div>
        {!props.currentUser.email?.trim() && <AddEmail {...props} />}

        <Spin spinning={loading} indicator={antIcon}>
          <Card>
            <Flex vertical gap="middle" flexGrow={1}>
              {/* Table Header */}
              <Flex align="center" justify="space-between">
                <Typography.Title level={4} style={{ margin: 0 }}>
                  {props.t('license-plates.citizen.list.total-elements', { totalElements })}
                </Typography.Title>

                <Flex align="center" gap="small">
                  {localStorage.getItem('center').toUpperCase() === 'VIC' && (
                    <ButtonPrimary
                      color="blue"
                      shape="round"
                      size="large"
                      onClick={() =>
                        props.history.push({
                          pathname: '/formulari-acces',
                          state: {
                            initialStep: 2,
                            singlePage: true,
                            username: props.currentUser.username,
                            authorizedZones: authorizedZones.filter((zone) => zone.validity !== 'DENIED'),
                          },
                        })
                      }
                    >
                      Sol·licitar nova matrícula
                      <MdAdd
                        style={{
                          verticalAlign: 'sub',
                          fontSize: '20',
                          marginLeft: '5px',
                        }}
                      />
                    </ButtonPrimary>
                  )}

                  {(userHasNonTrimestralAccess() ||
                    localStorage.getItem('center').toUpperCase() === 'TORELLO' ||
                    localStorage.getItem('center').toUpperCase() === 'NEXOTECH') && (
                    <ButtonPrimary
                      color="blue"
                      shape="round"
                      size="large"
                      onClick={() => props.history.push('/license-plates-add')}
                    >
                      {props.t('license-plates.citizen.add-button')}
                      <MdAdd
                        style={{
                          verticalAlign: 'sub',
                          fontSize: '20',
                          marginLeft: '5px',
                        }}
                      />
                    </ButtonPrimary>
                  )}
                </Flex>
              </Flex>

              {/* Table Content */}
              {localStorage.getItem('center').toUpperCase() === 'TORELLO' ? (
                <Table
                  dataSource={data}
                  columns={[
                    ...columns,
                    {
                      title: props.t('license-plates.citizen.list.table.reason'),
                      dataIndex: 'reason',
                      key: 'reason',
                    },
                    expireColumn,
                  ]}
                  pagination={{
                    pageSize: 10,
                    position: ['none', 'none'],
                  }}
                  bordered={true}
                />
              ) : (
                <Table
                  dataSource={data}
                  bordered={true}
                  columns={[...columns, expireColumn]}
                  pagination={{
                    pageSize: 10,
                    position: ['none', 'none'],
                  }}
                />
              )}

              {/* Table Footer */}
              <Flex gap="middle" align="center" justify="center">
                <ButtonPrimary
                  color="black"
                  onClick={() => setCurrentPage((prevPage) => prevPage - 1)}
                  shape="round"
                  size="middle"
                  disabled={!hasPreviousPage}
                >
                  <LuArrowLeft fontSize={20} />
                </ButtonPrimary>

                <Typography.Text>
                  {props.t('license-plates.citizen.list.page-of', {
                    currentPage: currentPage + 1,
                    totalPages: totalPages,
                  })}
                </Typography.Text>

                <ButtonPrimary
                  color="black"
                  onClick={() => setCurrentPage((prevPage) => prevPage + 1)}
                  shape="round"
                  size="middle"
                  disabled={!hasNextPage}
                >
                  <LuArrowRight fontSize={20} />
                </ButtonPrimary>
              </Flex>
            </Flex>
          </Card>
        </Spin>
      </div>
    </div>
  );
};

export default withTranslation('common')(LicensePlates);
