import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './assets/css/main.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import App from './App.js';
import common_es from './translations/es/common.json';
import common_ca from './translations/ca/common.json';
import common_ga from './translations/ga/common.json';
import common_eus from './translations/eus/common.json';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';

i18next.init({
  interpolation: { escapeValue: false },
  lng: 'es',
  resources: {
    es: {
      common: common_es,
    },
    ca: {
      common: common_ca,
    },
    ga: {
      common: common_ga,
    },
    eus: {
      common: common_eus,
    },
  },
});

ReactDOM.render(
  <BrowserRouter>
    <I18nextProvider i18n={i18next}>
      <App />
    </I18nextProvider>
  </BrowserRouter>,
  document.getElementById('root')
);
