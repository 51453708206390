import React from 'react';
import { Form, Input } from 'antd';
import { notification } from 'antd';
import * as UserRepository from '../../repository/user/UserRepository';
import ButtonPrimary from 'components/Buttons/ButtonPrimary';
import { withTranslation } from 'react-i18next';

const ChangePasswordForm = ({ t, updatedPassword }) => {
  const submitFirstLogin = (values) => {
    UserRepository.updatePassword(values.password)
      .then(() => {
        notification['success']({
          message: 'LPRConnect',
          description: t('change-password-page.success-update-password'),
        });
        updatedPassword();
      })
      .catch((error) => {
        notification['error']({
          message: 'LPRConnect',
          description: t(error.message),
        });
      });
  };

  const pattern_password_error = t('change-password-page.form.pattern-password');
  const diff_password_error = t('change-password-page.form.diff-password');

  return (
    <Form
      layout="vertical"
      onFinish={submitFirstLogin}
      size="large"
    >
      <Form.Item
        name="password"
        label={t('change-password-page.form.password')}
        rules={[
          {
            required: true,
            message: t('change-password-page.form.required-password'),
          },
          () => ({
            validator(_, value) {
              if (value.length < 6) {
                return Promise.reject(new Error(pattern_password_error));
              }
              const patron = new RegExp(
                '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&\\-_=+#~])[A-Za-z\\d@$!%*?&\\-_=+#~]+$'
              );
              if (!patron.test(value)) {
                return Promise.reject(new Error(pattern_password_error));
              }
              return Promise.resolve();
            },
          }),
        ]}
        hasFeedback
      >
        <Input.Password />
      </Form.Item>

      <Form.Item
        name="confirm"
        label={t('change-password-page.form.confirm-password')}
        dependencies={['password']}
        hasFeedback
        rules={[
          {
            required: true,
            message: t('change-password-page.form.required-confirm-password'),
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error(diff_password_error));
            },
          }),
        ]}
      >
        <Input.Password />
      </Form.Item>

      <ButtonPrimary
        color="black"
        htmlType="submit"
        size="large"
        shape="round"
        style={{ backgroundColor: '#990033', width: '100%' }}
      >
        {t('change-password-page.form.update-button')}
      </ButtonPrimary>
    </Form>
  );
};

export default withTranslation('common')(ChangePasswordForm);
