import React, { Component } from 'react';
import { withRouter, Route, Switch, Redirect } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import { Collapse, Layout, Modal, notification, Typography } from 'antd';
import { ACCESS_TOKEN } from './util/Constants.js';
import indexRoutes from './routes/index.jsx';

import LoadingIndicator from './components/LoadingIndicator';
import CitizenForm from 'views/CitizenForm/CitizenForm.jsx';
import LogoutView from 'views/Login/LogoutView.jsx';
import Pages from 'layouts/Pages/Pages.jsx';

import * as UserRepository from './repository/user/UserRepository.js';
import * as AuthRepository from './repository/auth/AuthRepository.js';
import Dashboard from 'layouts/Dashboard/Dashboard.jsx';

const { Paragraph, Text } = Typography;
const { Content } = Layout;

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUser: undefined,
      currentCenter: 'default',
      isAuthenticated: false,
      isLoading: false,
      modalVisible: false,
    };
    this.handleLogout = this.handleLogout.bind(this);
    this.handleLogoutWhenDifferentCenter = this.handleLogoutWhenDifferentCenter.bind(this);
    this.loadCurrentUser = this.loadCurrentUser.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
    this.redirect = this.redirect.bind(this);

    notification.config({
      placement: 'topRight',
      top: 70,
      duration: 3,
    });
  }

  async loadCurrentUser() {
    this.setState({
      isLoading: true,
    });
    try {
      const centerName = localStorage.getItem('center');
      const centerResponse = await AuthRepository.getCenter(centerName);
      const userResponse = await UserRepository.getCurrentUser();

      const currentUser = {
        ...userResponse,
      };

      if (centerName.toLowerCase() === userResponse.center.toLowerCase()) {
        i18next.changeLanguage(userResponse.language);
        this.setState({
          currentCenter: centerResponse,
          currentUser: currentUser,
          isAuthenticated: true,
          isLoading: false,
        });
      } else {
        notification['error']({
          message: 'ZinkinData Portal',
          description: "L'usuari no pertany a aquest centre.",
        });

        this.setState({ isLoading: false });

        this.handleLogoutWhenDifferentCenter();
      }
    } catch (error) {
      localStorage.removeItem('accessToken');
      this.setState({
        isLoading: false,
      });
    }
  }

  redirect() {
    switch (this.props.history.location.pathname) {
      case '/login':
      case '/login/vic':
      case '/login/torello':
      case '/login/rister':
      case '/pages/verify-mfa':
        if (this.state.currentUser.passwordExpired) {
          this.props.history.push({
            pathname: '/pages/change-password',
            state: {
              title: this.props.t('change-password-page.password-expire.title'),
              description: this.props.t('change-password-page.password-expire.description'),
            },
          });
        } else {
          this.props.history.push('/license-plates');
        }
        break;
      case '/pages/change-password':
        this.props.history.push('/license-plates');
        break;
      case '/':
      case '':
      default:
        break;
    }
  }

  componentWillMount() {
    this.loadCurrentUser();
  }

  handleLogoutWhenDifferentCenter() {
    localStorage.removeItem(ACCESS_TOKEN);

    this.setState({
      currentUser: undefined,
      isAuthenticated: false,
    });
  }

  handleLogout() {
    localStorage.removeItem(ACCESS_TOKEN);

    this.setState({
      currentUser: undefined,
      isAuthenticated: false,
    });

    try {
      if (window.location.hostname === 'illesvianants.vic.cat') {
        this.props.history.push('/login/vic');
      } else {
        switch (localStorage.getItem('center')) {
          case 'torello':
            this.props.history.push('/login/torello');
            break;
          case 'vic':
            this.props.history.push('/login/vic');
            break;
          case 'rister':
            this.props.history.push('/login/rister');
            break;
          case 'nexotech':
            this.props.history.push('/login');
            break;
          default:
            this.props.history.push('/login');
            break;
        }
      }
    } catch (error) {
      localStorage.removeItem('accessToken');
      this.props.history.push('/login');
    }
  }

  handleLogin() {
    this.loadCurrentUser().then(() => {
      this.setState({ modalVisible: true });
      notification.success({
        message: 'ZinkinData Portal',
        description: 'Has iniciat sessió amb èxit.',
      });
      this.redirect();
    });
  }

  isValidCenter() {
    try {
      return this.state.currentUser.center.toLowerCase() === localStorage.getItem('center').toLowerCase();
    } catch (error) {
      console.log(error);
    }

    return false;
  }

  render() {
    if (this.state.isLoading) {
      return <LoadingIndicator />;
    }
    return (
      <Layout>
        <Content>
          <div>
            <Switch>
              <Route
                exact
                path="/"
                render={(props) => {
                  try {
                    this.props.history.push('/license-plates');
                  } catch (error) {
                    this.props.history.push('/profile');
                  }
                  return null;
                }}
              />

              <Route
                path="/login"
                render={(props) => (
                  <Pages
                    handleLoginForm={this.handleLogin}
                    loadCurrentUser={this.loadCurrentUser}
                    redirect={this.redirect}
                    {...props}
                  />
                )}
              />

              <Route
                path="/pages/change-password"
                render={(props) => (
                  <Pages
                    handleLoginForm={this.handleLogin}
                    loadCurrentUser={this.loadCurrentUser}
                    redirect={this.redirect}
                    {...props}
                  />
                )}
              />

              <Route
                path="/pages/verify-email"
                render={(props) => (
                  <Pages
                    handleLogout={this.handleLogout}
                    {...props}
                  />
                )}
              />

              <Route
                path="/pages/verify-mfa"
                render={(props) => (
                  <Pages
                    handleLoginForm={this.handleLogin}
                    handleLogout={this.handleLogout}
                    {...props}
                  />
                )}
              />

              <Route
                path="/logout"
                render={(props) => (
                  <LogoutView
                    handleLogout={this.handleLogout}
                    {...props}
                  />
                )}
              />

              <Route
                path="/formulari-acces"
                render={(props) => <CitizenForm {...props} />}
              />

              <Route
                path="/"
                render={(props) =>
                  this.state.isAuthenticated && this.isValidCenter() ? (
                    <Dashboard
                      handleLogout={this.handleLogout}
                      currentUser={this.state.currentUser}
                      currentCenter={this.state.currentCenter}
                      {...props}
                    />
                  ) : window.location.hostname === 'illesvianants.vic.cat' ? (
                    <Redirect
                      to={{
                        pathname: '/login/vic',
                        state: { from: props.location },
                      }}
                    />
                  ) : (
                    <Redirect
                      to={{
                        pathname: '/login',
                        state: { from: props.location },
                      }}
                    />
                  )
                }
              />
            </Switch>

            <Modal
              title={this.props.t('login.terms-and-conditions.title')}
              open={this.state.modalVisible}
              centered
              okText={this.props.t('login.terms-and-conditions.confirm-button')}
              cancelText={this.props.t('login.terms-and-conditions.cancel-button')}
              cancelButtonProps={{ danger: true }}
              onOk={() => this.setState({ modalVisible: false })}
              onCancel={() => {
                this.setState({ modalVisible: false });
                this.handleLogout();
              }}
            >
              <Collapse ghost>
                <Collapse.Panel
                  key={'1'}
                  header={<Text strong>{this.props.t('login.terms-and-conditions.panel-title')}</Text>}
                >
                  <Paragraph style={{ textAlign: 'justify' }}>
                    {this.props.t('login.terms-and-conditions.description-1')}
                  </Paragraph>
                  <Paragraph style={{ textAlign: 'justify' }}>
                    {this.props.t('login.terms-and-conditions.description-2')}
                  </Paragraph>
                  <Paragraph style={{ textAlign: 'justify' }}>
                    {this.props.t('login.terms-and-conditions.description-3')}
                  </Paragraph>
                  <Paragraph style={{ textAlign: 'justify' }}>
                    {this.props.t('login.terms-and-conditions.description-4')}
                  </Paragraph>
                </Collapse.Panel>
              </Collapse>
            </Modal>
          </div>
        </Content>
      </Layout>
    );
  }
}

export default withRouter(withTranslation('common')(App));
