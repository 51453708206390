import React from 'react';
import ChangePasswordForm from 'views/Login/ChangePasswordForm';
import { Alert, Card, Space } from 'antd';
import { withTranslation } from 'react-i18next';

const ChangePasswordPage = ({ t, updatedPassword, location }) => {
  return (
    <Space
      align="center"
      direction="vertical"
      style={{ width: '100%', marginTop: '10%' }}
    >
      <Card
        title={t('change-password-page.title')}
        size="large"
        style={{ width: '500px' }}
      >
        <Alert
          message={t(location.state.title)}
          description={t(location.state.description)}
          type="info"
          showIcon
          style={{ marginBottom: '3%' }}
        />
        <ChangePasswordForm updatedPassword={updatedPassword} />
      </Card>
    </Space>
  );
};

export default withTranslation('common')(ChangePasswordPage);
