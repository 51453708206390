import React, { useState } from 'react';
import moment from 'moment';
import { Spin, Form, Space, Button, Radio, Typography, Checkbox, Flex, Modal } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { AiOutlineDelete, AiTwotoneFileText } from 'react-icons/ai';
import { postZoneAuthorizations, postFormDataZones } from 'repository/accessForm/AccessFormRepository';
import { ReasonsEnum, ZonesEnum } from './constants';

export const ZonesStep = ({
  initialFormValues,
  onFormFieldsChange,
  nextStep,
  prevStep,
  singlePage,
  username,
  history,
}) => {
  const [formFields, setFormFields] = useState({
    accessReason: initialFormValues.accessReason || undefined,
    selectedZones: initialFormValues.selectedZones || [],
    attachedFile: initialFormValues.attachedFile || undefined,
  });
  const [accessReasonError, setAccessReasonError] = useState('');
  const [selectedZonesError, setSelectedZonesError] = useState('');
  const [uploadError, setUploadError] = useState('');
  const [modalLoading, setModalLoading] = useState(false);
  const [zonesDisabled, setZonesDisabled] = useState(
    () =>
      initialFormValues.accessReason === ReasonsEnum.MAJOR_75_ANY_EMPADRONAT ||
      initialFormValues.accessReason === ReasonsEnum.ESTABLIMENT_LLICENCIA ||
      initialFormValues.accessReason === ReasonsEnum.MOBILITAT_REDUIDA
  );
  const [isUploadFieldVisible, setdisplayUploadComponent] = useState(
    () =>
      initialFormValues.accessReason === ReasonsEnum.LLOGATER_NO_EMPADRONAT ||
      initialFormValues.accessReason === ReasonsEnum.ACCES_CAUSAL ||
      initialFormValues.accessReason === ReasonsEnum.MOBILITAT_REDUIDA
  );

  function validateFields() {
    let hasError = false;

    if (!formFields.accessReason) {
      setAccessReasonError("Si us plau, indiqueu una rao d'accés");
      hasError = true;
    } else {
      setAccessReasonError('');
    }

    if (formFields.selectedZones.length === 0) {
      setSelectedZonesError('Si us plau, indiqueu una zona');
      hasError = true;
    } else {
      if (
        formFields.accessReason !== ReasonsEnum.MAJOR_75_ANY_EMPADRONAT &&
        formFields.accessReason !== ReasonsEnum.ESTABLIMENT_LLICENCIA &&
        formFields.accessReason !== ReasonsEnum.MOBILITAT_REDUIDA
      ) {
        if (formFields.selectedZones.length > 1) {
          setSelectedZonesError('Només es pot seleccionar una única zona');
          hasError = true;
        } else {
          setSelectedZonesError('');
        }
      }
    }

    if (isUploadFieldVisible) {
      if (!formFields.attachedFile && formFields.accessReason !== ReasonsEnum.MOBILITAT_REDUIDA) {
        setUploadError('Es obligatori pujar el fitxer');
        hasError = true;
      } else {
        setUploadError('');
      }
    }

    return hasError;
  }

  const getZoneName = (zoneId) => {
    const zone = Object.values(ZonesEnum).find((zone) => zone.id === zoneId);
    return zone.name;
  };

  const onFinish = async () => {
    const hasError = validateFields();
    if (hasError) {
      return;
    }

    if (singlePage) {
      await createZoneAuthorizations();
    } else {
      onFormFieldsChange(formFields);
      nextStep();
    }
  };

  async function createZoneAuthorizations() {
    setModalLoading(true);

    const zones = {
      username: username,
      accessReason: formFields.accessReason,
      selectedZones: formFields.selectedZones,
      attachedFile: formFields.attachedFile,
    };

    const form = {
      DNI: username,
      accessReason: zones.accessReason,
      zones: zones.selectedZones.map((zone) => {
        return { id: zone, name: getZoneName(zone) };
      }),
      zoneAttachedFileName: formFields.attachedFile?.name,
    };

    const timestamp = moment().format('YYYY-MM-DD HH:mm:ss').toString();

    try {
      await postFormDataZones(form, formFields.attachedFile, timestamp);
      await postZoneAuthorizations(zones, timestamp, 'vic');
      setModalLoading(false);
      Modal.info({
        title: 'Petició enviada',
        content: <div>S'ha enviat la petició d'accés a la zona correctament.</div>,
        okText: 'Acceptar',
        onOk: () => history.push('/citizen-authoritzations'),
        centered: true,
      });
    } catch (error) {
      Modal.error({
        title: 'Error',
        content: <div>Hi ha hagut un error en l'enviament de la sol·licitud.</div>,
        okText: 'Tornar al menu principal',
        onOk: () => history.push('/license-plates'),
        centered: true,
      });
    }
  }

  const renderUploadText = () => {
    switch (formFields.accessReason) {
      case ReasonsEnum.LLOGATER_NO_EMPADRONAT:
        return 'Cliqueu per afegir el contracte de lloguer';
      case ReasonsEnum.ACCES_CAUSAL:
        return 'Cliqueu per afegir l’acreditació de la necessitat';
      case ReasonsEnum.MOBILITAT_REDUIDA:
        return 'Adjunteu la targeta de mobilitat reduïda si no l’heu tramitat a Vic';
      default:
        return null;
    }
  };

  const resetFile = () => {
    setFormFields((prev) => {
      return { ...prev, attachedFile: undefined };
    });
    const element = document.getElementById(`attachedFile`);
    if (element) {
      element.value = '';
    }
  };

  const renderFile = (fileName) => {
    return (
      fileName && (
        <Flex
          gap="small"
          align="center"
          justify="space-between"
          style={{
            padding: '5px',
            borderRadius: '5px',
            border: '1px solid lightgray',
          }}
        >
          <Flex
            align="center"
            gap="small"
          >
            <AiTwotoneFileText style={{ fontSize: '20px', width: '50px' }} />
            <p
              style={{
                overflowWrap: 'break-word',
                wordBreak: 'break-word',
              }}
            >
              {fileName}
            </p>
          </Flex>
          <AiOutlineDelete
            style={{ fontSize: '17px', cursor: 'pointer', width: '50px' }}
            onClick={() => {
              resetFile();
            }}
          />
        </Flex>
      )
    );
  };

  const fontWeightNormal = {
    fontWeight: '400',
  };
  const formStyle = {
    width: '100%',
    maxWidth: '500px',
  };

  return (
    <Spin spinning={modalLoading}>
      <Flex
        vertical
        gap="large"
        style={formStyle}
      >
        <Flex
          vertical
          gap="small"
        >
          <div style={{ fontWeight: 'bold' }}>
            <span style={{ color: 'red' }}>* </span>Indiqueu una de les opcions segons escaigui (si compliu dues o més
            condicions, trieu-ne una):
          </div>

          <Radio.Group
            onChange={(e) => {
              const currentValue = e.target.value;
              const previousValue = formFields.accessReason;
              let newState = { ...formFields };
              newState.accessReason = currentValue;

              // Determinar si se reinicia el campo de seleccion de zonas
              if (
                previousValue === ReasonsEnum.MAJOR_75_ANY_EMPADRONAT ||
                previousValue === ReasonsEnum.ESTABLIMENT_LLICENCIA ||
                previousValue === ReasonsEnum.MOBILITAT_REDUIDA
              ) {
                newState.selectedZones = [];
              }

              // Determinar si se deshabilita el poder seleccionar zonas
              if (
                currentValue === ReasonsEnum.MAJOR_75_ANY_EMPADRONAT ||
                currentValue === ReasonsEnum.ESTABLIMENT_LLICENCIA ||
                currentValue === ReasonsEnum.MOBILITAT_REDUIDA
              ) {
                newState.selectedZones = [23, 24, 25, 7];
                setZonesDisabled(true);
              } else {
                setZonesDisabled(false);
              }

              setFormFields(newState);

              // Determinar si se muestra el campo de agregar documentacion
              if (
                currentValue === ReasonsEnum.LLOGATER_NO_EMPADRONAT ||
                currentValue === ReasonsEnum.ACCES_CAUSAL ||
                currentValue === ReasonsEnum.MOBILITAT_REDUIDA
              ) {
                setdisplayUploadComponent(true);
              } else {
                setdisplayUploadComponent(false);
                resetFile();
              }
            }}
            value={formFields.accessReason}
          >
            <Space direction="vertical">
              <Typography level={5}>Soc propietari:</Typography>
              {Object.values(ReasonsEnum)
                .slice(0, 3)
                .map((reason) => (
                  <Radio
                    key={reason}
                    value={String(reason)}
                    style={fontWeightNormal}
                  >
                    {reason}
                  </Radio>
                ))}

              <Typography
                style={{ marginTop: '10px' }}
                level={5}
              >
                No soc propietari:
              </Typography>
              {Object.values(ReasonsEnum)
                .slice(3, 5)
                .map((reason) => (
                  <Radio
                    key={reason}
                    value={String(reason)}
                    style={fontWeightNormal}
                  >
                    {reason}
                  </Radio>
                ))}

              <Typography
                style={{ marginTop: '10px' }}
                level={5}
              >
                Altres:
              </Typography>
              {Object.values(ReasonsEnum)
                .slice(5)
                .map((reason) => (
                  <Radio
                    key={reason}
                    value={String(reason)}
                    style={fontWeightNormal}
                  >
                    {reason}
                  </Radio>
                ))}
            </Space>
          </Radio.Group>
          {accessReasonError && <div style={{ color: 'red' }}>{accessReasonError}</div>}
        </Flex>

        <Flex
          vertical
          gap="small"
        >
          <div style={{ fontWeight: 'bold' }}>
            <span style={{ color: 'red' }}>* </span>Indiqueu una única zona:
          </div>

          <Checkbox.Group
            onChange={(checkedValues) => {
              setFormFields((prev) => {
                return { ...prev, selectedZones: checkedValues };
              });
            }}
            value={formFields.selectedZones}
          >
            <Flex vertical>
              {Object.values(ZonesEnum).map((zone) => {
                return (
                  <Checkbox
                    key={zone.id}
                    value={zone.id}
                    disabled={zonesDisabled}
                    checked={zonesDisabled}
                    style={fontWeightNormal}
                  >
                    {zone.name}
                  </Checkbox>
                );
              })}
            </Flex>
          </Checkbox.Group>
          {selectedZonesError && <div style={{ color: 'red' }}>{selectedZonesError}</div>}
        </Flex>

        {isUploadFieldVisible && (
          <Flex
            vertical
            gap="small"
          >
            <div style={{ fontWeight: 'bold' }}>
              <span style={{ color: 'red' }}>* </span>Afegiu la documentació demanada:
            </div>
            <div>
              <div
                style={{
                  width: '100%',
                  background: 'white',
                  borderRadius: '10px',
                  border: '2px solid rgb(0,0,0,0.2)',
                  padding: '10px',
                  marginBottom: '10px',
                  borderStyle: 'dashed',
                  cursor: 'pointer',
                }}
              >
                <label
                  style={{
                    cursor: 'pointer',
                  }}
                  htmlFor={`attachedFile`}
                  onMouseEnter={(e) => {
                    e.currentTarget.style.border = '2px solid rgb(52, 116, 235,0.5)';
                    e.currentTarget.style.borderStyle = 'dashed';
                  }}
                  onMouseLeave={(e) => {
                    e.currentTarget.style.border = '2px solid rgb(0,0,0,0.2)';
                    e.currentTarget.style.borderStyle = 'dashed';
                  }}
                >
                  <Flex
                    vertical
                    justify="center"
                    align="center"
                    gap="small"
                    style={{ textAlign: 'center' }}
                  >
                    <UploadOutlined />
                    <div>{renderUploadText()}</div>
                  </Flex>
                </label>
              </div>

              {renderFile(formFields.attachedFile?.name)}
              <input
                id={`attachedFile`}
                style={{ display: 'none' }}
                type="file"
                onChange={(event) => {
                  const newFile = event.target.files[0];
                  setFormFields((prev) => {
                    return { ...prev, attachedFile: newFile };
                  });
                }}
              />
            </div>

            {uploadError && <div style={{ color: 'red' }}>{uploadError}</div>}
          </Flex>
        )}

        <Form.Item>
          {!singlePage && (
            <Button
              style={{ margin: '5px' }}
              onClick={() => {
                onFormFieldsChange(formFields);
                prevStep();
              }}
            >
              Tornar al pas anterior
            </Button>
          )}
          <Button
            type="primary"
            onClick={onFinish}
            style={{ float: 'right', margin: '5px' }}
          >
            {singlePage ? 'Finalitzar' : 'Següent'}
          </Button>
        </Form.Item>
      </Flex>
    </Spin>
  );
};
